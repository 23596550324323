import style from "./Productions.module.css";
import Nav from "./components/Nav";
import ContactButton from "./components/ContactButton";

import img1 from "./assets/prod/coupe/1.jpeg";
import img2 from "./assets/prod/coupe/2.jpeg";
import img3 from "./assets/prod/coupe/3.jpeg";
import img4 from "./assets/prod/coupe/4.jpeg";
import img5 from "./assets/prod/coupe/5.jpeg";
import img6 from "./assets/prod/coupe/6.jpeg";
import img7 from "./assets/prod/coupe/7.jpeg";
import img8 from "./assets/prod/coupe/8.jpeg";
import img9 from "./assets/prod/coupe/9.jpeg";
import img10 from "./assets/prod/coupe/10.jpeg";
import img11 from "./assets/prod/coupe/11.jpeg";
import img12 from "./assets/prod/coupe/12.jpeg";
import img13 from "./assets/prod/coupe/13.jpeg";
import img14 from "./assets/prod/coupe/14.jpeg";
// import img15 from "./assets/prod/coupe/15.jpg";
// import img16 from "./assets/prod/coupe/16.jpg";
// import img17 from "./assets/prod/coupe/17.jpg";
// import img18 from "./assets/prod/coupe/18.jpg";

import img15 from "./assets/prod/aroma/1.jpeg";
import img16 from "./assets/prod/aroma/2.jpeg";
import img17 from "./assets/prod/aroma/3.jpeg";
import img18 from "./assets/prod/aroma/4.jpeg";
import img19 from "./assets/prod/aroma/5.jpeg";
import img20 from "./assets/prod/aroma/6.jpeg";
import img21 from "./assets/prod/aroma/7.jpeg";
import img22 from "./assets/prod/aroma/8.jpeg";
import img23 from "./assets/prod/aroma/9.jpeg";
import img24 from "./assets/prod/aroma/10.jpeg";
import img25 from "./assets/prod/aroma/11.jpeg";
import img26 from "./assets/prod/aroma/12.jpeg";
import img27 from "./assets/prod/aroma/13.jpeg";
import img28 from "./assets/prod/aroma/14.jpeg";

import img29 from "./assets/prod/comes/1.jpeg";
import img30 from "./assets/prod/comes/2.jpeg";
import img31 from "./assets/prod/comes/3.jpeg";
import img32 from "./assets/prod/comes/4.jpeg";
import img33 from "./assets/prod/comes/5.jpeg";
import img34 from "./assets/prod/comes/6.jpeg";
import img35 from "./assets/prod/comes/7.jpeg";
import img36 from "./assets/prod/comes/8.jpeg";
import img37 from "./assets/prod/comes/9.jpeg";
import img38 from "./assets/prod/comes/10.jpeg";
import img39 from "./assets/prod/comes/11.jpeg";
import img40 from "./assets/prod/comes/12.jpeg";
import img41 from "./assets/prod/comes/13.jpeg";
import img42 from "./assets/prod/comes/14.jpeg";


import lis1 from "./assets/lisianthus/lis10.png";


const Production = () => {
    return (
        <div className={style.bodyDiv}>
            <Nav />
            <div className={style.MainDiv}>
                <div className={style.MainHead}>
                    <h1>Productions</h1>
                </div>
                <p>
                    Afin d'avoir des produits locaux et de saison, j'ai voulu
                    produire moi-même les fleurs que j’utilise pour faire vos
                    différentes compositions. Ma culture est sans produit
                    chimique et en culture raisonnée afin de mieux respecter
                    l’environnement. Pour mieux répondre à d'autres demandes, il
                    m’arrive de me fournir chez un grossiste à quelques
                    kilomètres de chez moi.
                </p>

                <div className={style.second}>
                    <div className={style.imgsContainer}>
                        <img alt="^roduction de plantes" src={img1} style={{objectFit : "cover" , top: `2%`, left: `5%` }} />
                        <img alt="^roduction de plantes" src={img2} style={{objectFit : "cover" , top: `80%`, left: `70%` }} />
                        <img alt="^roduction de plantes" src={img3} style={{objectFit : "cover" , top: `6%`, left: `78%` }} />
                        <img alt="^roduction de plantes" src={img4} style={{objectFit : "cover" , top: `72%`, left: `16%` }} />
                        <img alt="^roduction de plantes" src={img5} style={{objectFit : "cover" , top: `4%`, left: `37%` }} />
                        <img alt="^roduction de plantes" src={img6} style={{objectFit : "cover" , top: `60%`, left: `57%` }} />
                        <img alt="^roduction de plantes" src={img7} style={{objectFit : "cover" , top: `40%`, left: `70%` }} />
                        <img alt="^roduction de plantes" src={img8} style={{objectFit : "cover" , top: `15%`, left: `52%` }} />
                        <img alt="^roduction de plantes" src={img9} style={{objectFit : "cover" , top: `47%`, left: `8%` }} />
                        <img alt="^roduction de plantes" src={img10} style={{objectFit : "cover" , top: `54%`, left: `41%` }} />
                        <img alt="^roduction de plantes" src={img11} style={{objectFit : "cover" , top: `16%`, left: `17%` }} />
                        <img alt="^roduction de plantes" src={img12} style={{objectFit : "cover" , top: `80%`, left: `1%` }} />
                        <img alt="^roduction de plantes" src={img13} style={{objectFit : "cover" , top: `34%`, left: `36%` }} />
                        <img alt="^roduction de plantes" src={img14} style={{objectFit : "cover" , top: `80%`, left: `36%` }} />
                    </div>
                    <div className={style.text}>
                        <div className={style.spacer}>
                            <h2>Fleurs coupées</h2>
                            <p>
                                Ma passion et mon savoir pour les fleurs
                                m'amènent à avoir de très belles fleurs durant
                                une grande partie de l'année. Ma production me
                                permet de cueillir diverses fleurs de tailles et
                                de couleurs très variées afin de créer de belles
                                compositions tout en respectant l’environnement.
                            </p>
                        </div>
                    </div>
                </div>

                <div className={style.first}>
                    <div className={style.text}>
                        <div className={style.spacer}>
                            <h2>Plantes aromatiques &</h2>
                            <h2>médicinales</h2>
                            <p>
                                Toutes les plantes sont utiles, qu'elles
                                proviennent de la nature ou bien de votre
                                jardin. Des belles fleurs cultivées aux plantes
                                sauvages, elles possèdent diverses propriétés et
                                peuvent potentiellement être utilisées en tant
                                que plantes médicinales. Cette réflexion m’a
                                poussée à agrandir ma production en
                                collaboration avec une naturopathe. Ma culture
                                produite de manière raisonnée, sans ajout de
                                produit chimique et en respectant les besoins
                                des plantes permet un produit plus respectueux
                                et comestible.
                            </p>
                        </div>
                    </div>
                    <div className={style.imgsContainer}>
                        <img alt="plantes médicinales" src={img15} style={{ objectFit : 'cover', top: `2%`, left: `5%` }} />
                        <img alt="plantes médicinales" src={img16} style={{ objectFit : 'cover', top: `80%`, left: `70%` }} />
                        <img alt="plantes médicinales" src={img17} style={{ objectFit : 'cover', top: `6%`, left: `78%` }} />
                        <img alt="plantes médicinales" src={img18} style={{ objectFit : 'cover', top: `72%`, left: `16%` }} />
                        <img alt="plantes médicinales" src={img19} style={{ objectFit : 'cover', top: `4%`, left: `37%` }} />
                        <img alt="plantes médicinales" src={img20} style={{ objectFit : 'cover', top: `60%`, left: `57%` }} />
                        <img alt="plantes médicinales" src={img21} style={{ objectFit : 'cover', top: `40%`, left: `70%` }} />
                        <img alt="plantes médicinales" src={img22} style={{ objectFit : 'cover', top: `15%`, left: `52%` }} />
                        <img alt="plantes médicinales" src={img23} style={{ objectFit : 'cover', top: `47%`, left: `8%` }} />
                        <img alt="plantes médicinales" src={img24} style={{ objectFit : 'cover', top: `54%`, left: `41%` }} />
                        <img alt="plantes médicinales" src={img25} style={{ objectFit : 'cover', top: `16%`, left: `17%` }} />
                        <img alt="plantes médicinales" src={img26} style={{ objectFit : 'cover', top: `80%`, left: `1%` }} />
                        <img alt="plantes médicinales" src={img27} style={{ objectFit : 'cover', top: `34%`, left: `36%` }} />
                        <img alt="plantes médicinales" src={img28} style={{ objectFit : 'cover', top: `80%`, left: `36%` }} />
                    </div>
                </div>

                <div className={style.second}>
                    <div className={style.imgsContainer}>
                        <img alt="fleurs comestibles" src={img29} style={{ objectFit : 'cover', top: `2%`, left: `5%` }}/>
                        <img alt="fleurs comestibles" src={img30} style={{ objectFit : 'cover', top: `80%`, left: `70%` }}/>
                        <img alt="fleurs comestibles" src={img31} style={{ objectFit : 'cover', top: `6%`, left: `78%` }}/>
                        <img alt="fleurs comestibles" src={img32} style={{ objectFit : 'cover', top: `72%`, left: `16%` }}/>
                        <img alt="fleurs comestibles" src={img33} style={{ objectFit : 'cover', top: `4%`, left: `37%` }}/>
                        <img alt="fleurs comestibles" src={img34} style={{ objectFit : 'cover', top: `60%`, left: `57%` }}/>
                        <img alt="fleurs comestibles" src={img35} style={{ objectFit : 'cover', top: `40%`, left: `70%` }}/>
                        <img alt="fleurs comestibles" src={img36} style={{ objectFit : 'cover', top: `15%`, left: `52%` }}/>
                        <img alt="fleurs comestibles" src={img37} style={{ objectFit : 'cover', top: `47%`, left: `8%` }}/>
                        <img alt="fleurs comestibles" src={img38} style={{ objectFit : 'cover', top: `54%`, left: `41%` }}/>
                        <img alt="fleurs comestibles" src={img39} style={{ objectFit : 'cover', top: `16%`, left: `17%` }}/>
                        <img alt="fleurs comestibles" src={img40} style={{ objectFit : 'cover', top: `80%`, left: `1%` }}/>
                        <img alt="fleurs comestibles" src={img41} style={{ objectFit : 'cover', top: `34%`, left: `36%` }}/>
                        <img alt="fleurs comestibles" src={img42} style={{ objectFit : 'cover', top: `80%`, left: `36%` }}/>
                    </div>
                    <div className={style.text}>
                        <div className={style.spacer}>
                            <h2>Fleurs comestibles</h2>
                            <p>
                                La production de fleurs et le plaisir que cela
                                me procure m'ont amenée à me pencher sur la
                                culture des fleurs comestibles. Cette dernière
                                permet de mettre en valeur la fleur dans
                                l’assiette, de l'entrée au dessert ou dans vos
                                boissons. Une belle manière de proposer des
                                fleurs sous un aspect inattendu.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <ContactButton />
            <img className={style.lisianthus1} src={lis1} alt="" />
        </div>
    );
};

export default Production;
