import Nav from "./components/Nav";
import style from "./contact.module.css";
import React, { useState } from "react";

import { API_URL } from "./components/API-URL";

import FacebookIcon from "@mui/icons-material/Facebook";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InstagramIcon from "@mui/icons-material/Instagram";
import lis1 from "./assets/lisianthus/lis1.png";
import lis2 from "./assets/lisianthus/lis11.png";

// function isValidPhoneNumber(phoneNumber) {
//     const regex = /^(\+\33|06|07)[0-9]{9}$/;
//     return regex.test(phoneNumber);
// }

function isValidEmail(email) {
    const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return regex.test(email);
}

const Contact = () => {
    const [email, setEmail] = useState(undefined);
    const [phone, setPhone] = useState(undefined);
    const [message, setMessage] = useState(undefined);
    const [error, setError] = useState("");
    const [disable, setDisable] = useState(false);

    const send = async () => {
        setDisable(true);
        if (!email && !phone) {
            setError("Veuillez renseigner un email ou un numéro de téléphone");
            setDisable(false);
            return;
        }
        if (!message) {
            setError("Veuillez renseigner un message");
            setDisable(false);
            return;
        }
        // if (!isValidPhoneNumber(phone)) {
        //     setError("Veuillez renseigner un numéro de téléphone valide");
        //     setDisable(false);
        //     return;
        // }

        if (!isValidEmail(email)) {
            setError("Veuillez renseigner un email valide");
            setDisable(false);
            return;
        }

        const req = await fetch(`${API_URL}/api/contact`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email,
                phone,
                message,
            }),
        });

        if (req.status !== 200) {
            setError(
                "Une erreur est survenue, veuillez réessayer plus tard ou me contacter directement par email: armelle.zaid@gmail.com"
            );
        } else {
            setEmail("");
            setPhone("");
            setMessage("");
        }
        setDisable(false);
    };

    return (
        <div className={style.bodyDiv}>
            <img className={style.lisianthus1} src={lis1} alt="" />
            <img className={style.lisianthus2} src={lis2} alt="" />
            <Nav />
            <div className={style.MainDiv}>
                <div className={style.MainHead}>
                    <h1>Discutons de</h1>
                    <h1>votre projet</h1>
                </div>
                <div className={style.text}>
                    <p>
                        N’hésitez pas à me contacter pour discuter de vos
                        différents projets. Je suis en capacité d’embellir des
                        événements divers et variés et enthousiaste d’en
                        découvrir de nouveaux! Nous pouvons échanger à distance
                        ou nous rencontrer sur wattwiller ou à votre domicile
                        afin de discuter et de faire le point de vos besoins.
                    </p>
                </div>
                <div className={style.contacts}>
                    <div className={style.infos}>
                        <div className={style.icons}>
                            <div className={style.phone}>
                                <CallIcon />
                            </div>
                            <div className={style.email}>
                                <EmailIcon />
                            </div>
                            <div className={style.facebook}>
                                <FacebookIcon />
                            </div>
                            <div className={style.facebook}>
                                <InstagramIcon />
                            </div>
                            <div className={style.localisation}>
                                <LocationOnIcon />
                            </div>
                        </div>
                        <div className={style.data}>
                            <h4>07 70 10 40 72</h4>
                            <h4
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                    window.open("mailto:armelle.zaid@gmail.com")
                                }
                            >
                                armelle.zaid@gmail.com
                            </h4>
                            <h4
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                    window.open(
                                        "https://www.facebook.com/profile.php?id=100090888677016"
                                    )
                                }
                            >
                                Armelle-fleuriste-Wattwiller
                            </h4>
                            <h4
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                    window.open(
                                        "https://www.instagram.com/armelle.fleuriste.wattwiller?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                                    )
                                }
                            >
                                armelle.fleuriste.wattwiller
                            </h4>
                            <h4>1A rue de Berrwiller, 68700 WATTWILLER</h4>
                        </div>
                    </div>
                </div>

                <div className={style.map}>
                    <div className={style.mapimg}>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2678.1375284706032!2d7.181073216083428!3d47.83692897920059!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479181c80de7c0af%3A0x21997104cb618eb7!2s1%20Rue%20de%20Berrwiller%2C%2068700%20Wattwiller!5e0!3m2!1sfr!2sfr!4v1678292492962!5m2!1sfr!2sfr"
                            width="600"
                            height="450"
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            title="map"
                        ></iframe>
                    </div>
                </div>

                <div className={style.form}>
                    <div>
                        <input
                            type="email"
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                        />
                        <input
                            type="phone"
                            placeholder="Téléphone"
                            onChange={(e) => setPhone(e.target.value)}
                            value={phone}
                        />
                        <textarea
                            placeholder="Message"
                            onChange={(e) => setMessage(e.target.value)}
                            value={message}
                        />
                    </div>

                    <button disabled={disable} onClick={send}>
                        Envoyer
                    </button>
                    <p style={{ color: "red" }}>{error}</p>
                </div>
            </div>
        </div>
    );
};

export default Contact;
