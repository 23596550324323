import Nav from "./components/Nav";
import style from "./Accueil.module.css";
import ContactButton from "./components/ContactButton";

import lis1 from "./assets/lisianthus/lis1.png";

const Accueil = () => {
    return (
        <div className={style.bodyDiv}>
            <Nav dark={true} />
            <img className={style.lisianthus1} src={lis1} alt="" />
            <div className={style.MainDiv}>
                <div className={style.MainHead}>
                    <h1>Les Jardins</h1>
                    <h1>de la Source</h1>
                </div>
                <h2>
                    Votre fleuriste de proximité pour Wattwiller et ses
                    alentours
                </h2>
                <p>
                    Les jardins de la source est une entreprise de floriculture
                    située à Wattwiller au pied des montagnes qui produit des
                    fleurs coupées, comestibles, des plantes aromatiques et
                    médicinales dans le respect de l’environnement et des
                    saisons. Je mets tout en œuvre pour vous faire de belles
                    compositions, vous donner les meilleurs conseils et vous
                    mettre à disposition le matériel dont vous aurez besoin en
                    location.
                </p>
            </div>
            <ContactButton />
        </div>
    );
};

export default Accueil;
