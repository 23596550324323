import style from "./Mariage.module.css";
import Nav from "./components/Nav";
import ContactButton from "./components/ContactButton";
import { useNavigate } from "react-router-dom";

import img1 from "./assets/mariage/1.jpg";
import img2 from "./assets/mariage/2.jpg";
import img3 from "./assets/mariage/3.jpg";

import img4 from "./assets/mariage/4.jpg";
import img5 from "./assets/mariage/5.jpg";
import img6 from "./assets/mariage/6.jpg";
import img7 from "./assets/mariage/7.jpg";
import img8 from "./assets/mariage/8.jpg";


import lis1 from "./assets/lisianthus/lis5.png";

const Category = ({ name, id, navigate }) => {
    return (
        <button
            className={style.category}
            onClick={() =>
                navigate(`/album?id=${id}&name=${name}&type=Mariage`)
            }
        >
            <h2>{name}</h2>
        </button>
    );
};

const Mariage = () => {
    const navigate = useNavigate();

    return (
        <div className={style.bodyDiv}>
            <img className={style.lisianthus1} src={lis1} alt="" />
            <Nav />
            <div className={style.MainDiv}>
                <h1>Mariage</h1>
                <div className={style.partContainer}>
                    <p>
                        Travaillant depuis plusieurs années dans la création, la
                        mise en valeur et le fleurissement d'évènements, c’est
                        avec de l'expérience, de la joie et de la bienveillance
                        que je vous accompagnerai dans les préparatifs de votre
                        mariage pour en faire le plus beau jour de votre vie.
                    </p>
                    <p>
                        Des fleurs fraîches de saison, des contenants variés en
                        location et des décorations originales permettront
                        d’embellir les lieux de votre mariage selon le style,
                        les couleurs ou la variété de fleurs de votre choix. Je
                        ferai de mon mieux pour répondre à vos attentes et vous
                        émerveiller.
                    </p>
                    <p>
                        Mon professionnalisme vous permettra d’obtenir les
                        meilleurs conseils dès le premier rendez-vous sans
                        oublier aucun détail ce qui fera toute la différence et
                        vous permettra d’avoir un premier devis clair et précis.
                    </p>
                    <div className={style.imgContainer}>
                        <div className={style.centerer1}>
                            <img
                                alt="mariés"
                                src={img1}
                                className={style.img1}
                            />
                            <img
                                alt="décoration de salle"
                                src={img2}
                                className={style.img2}
                            />
                            <img
                                alt="bouquet de mariage"
                                src={img3}
                                className={style.img3}
                            />
                        </div>
                    </div>
                    <p>
                        Tous les moments du mariage sont importants c'est
                        pourquoi nous échangerons sur le choix du bouquet de la
                        mariée, des boutonnières, des bracelets, de décoration
                        de cérémonie, de décoration de voiture, du fleurissement
                        de votre apéritif et de votre repas ainsi que des arches
                        et diverses structures.
                    </p>

                    <div className={style.cardsContainer}>
                        <div>
                            <button
                                onClick={() =>
                                    navigate(
                                        "/album?id=accessoires&name=Accessoires"
                                    )
                                }
                            >
                                <img src={img4} alt="accessoire de mariage" />
                            </button>
                            <h3>Accessoires</h3>
                        </div>
                        <div>
                            <button
                                onClick={() =>
                                    navigate(
                                        "/album?id=bouquet&name=Bouquets de Mariée"
                                    )
                                }
                            >
                                <img src={img5} alt="bouquet de mariage" />
                            </button>
                            <h3>Bouquets de Mariée</h3>
                        </div>
                        <div>
                            <button
                                onClick={() =>
                                    navigate(
                                        "/album?id=structures&name=Structures _ panneaux"
                                    )
                                }
                            >
                                <img src={img6} alt="structure pour mariage" />
                            </button>
                            <h3>Structures & panneaux</h3>
                        </div>
                        <div>
                            <button
                                onClick={() =>
                                    navigate(
                                        "/album?id=ceremonies&name=Cérémonies"
                                    )
                                }
                            >
                                <img src={img7} alt="céremonie de mariage" />
                            </button>
                            <h3>Cérémonies</h3>
                        </div>
                        <div>
                            <button
                                onClick={() =>
                                    navigate(
                                        "/album?id=decorations&name=Décorations de salle"
                                    )
                                }
                            >
                                <img src={img8} alt="décoration de salle de mariage" />
                            </button>
                            <h3>Décorations de salle</h3>
                        </div>
                    </div>

                    <p>
                        Votre mariage est unique et très personnel, nous
                        pourrons développer votre décoration et votre style en
                        se basant sur différents thèmes tels que la nature, le
                        chic, le champêtre et bien d'autres encore, toujours
                        adaptées à vos couleurs et réfléchis en fonction du lieu.
                    </p>
                </div>
                <div className={style.mariageCats}>
                    <Category name="Nature" id="nature" navigate={navigate} />
                    <Category name="Chic" id="chic" navigate={navigate} />
                    <Category
                        name="Champêtre"
                        id="champetre"
                        navigate={navigate}
                    />
                    <Category name="Boème" id="boeme" navigate={navigate} />
                    <Category
                        name="Tout en couleur"
                        id="color"
                        navigate={navigate}
                    />
                    <Category name="À Thème " id="theme" navigate={navigate} />
                </div>
            </div>
            <ContactButton />
        </div>
    );
};

export default Mariage;
